import axios from '~/utilsjs/axios'
import { config } from '~/utilsjs'

export const loadPanoramas = () => async (dispatch, getState) => {
  const { auth } = getState()
  let res, options
  options = Object.assign({}, auth.AuthorizedOptions)
  try {
    res = await axios.get('/api/images/', options)
  } catch (e) {
    console.log('error: ', e)
    return
  }
  dispatch({
    type: 'setPanoramas',
    count: res.data.count,
    panoramas: res.data.results,
  })

  while (res.data.next) {
    try {
      res = await axios.get(res.data.next.replace(config.urls.https, ''), options)
    } catch (e) {
      console.log('error: ', e)
      return
    }
    dispatch({
      type: 'addPanoramas',
      panoramas: res.data.results,
    })
  }
}

export const loadPanorama = ({id}) => async (dispatch, getState) => {
  const { auth } = getState()
  let res, options
  options = Object.assign({}, auth.AuthorizedOptions)
  try {
    res = await axios.get('/api/images/'+id+'/', options)
  } catch (e) {
    console.log('error: ', e)
    return
  }
  dispatch({
    type: 'setPanorama',
    id: res.data.id,
    text: res.data.text,
    image_url: res.data.image_url,
  })
}

export const setPanorama = ({id, text, image_url}) => async (dispatch, getState) => {
  dispatch({
    type: 'setPanorama', id, text, image_url
  })
}
