import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './containers/app/App.jsx'
import 'bootstrap/dist/css/bootstrap.css'
import 'leaflet/dist/leaflet.css'
import Leaflet from 'leaflet'
import * as serviceWorker from './serviceWorker'
import store from './redux/store'

const iconRetinaUrl = require('leaflet/dist/images/marker-icon-2x.png')
const iconUrl = require('leaflet/dist/images/marker-icon.png')
const shadowUrl = require('leaflet/dist/images/marker-shadow.png')
require('aframe-orbit-controls')

delete Leaflet.Icon.Default.prototype._getIconUrl
Leaflet.Icon.Default.mergeOptions({ iconRetinaUrl, iconUrl, shadowUrl })

require('./styles/index.scss')

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
