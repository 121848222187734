import React, { Component } from 'react'

export default class AerialMapController extends Component {
  componentDidMount() {
    if (!this.props.auth.id) return
    const { actions, match } = this.props
    actions.loadPanoramas()
  }
  onMapClick(e) {
    console.log('e', e)
  }
}