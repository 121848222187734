import React from 'react'
import { Map, Marker, Popup, TileLayer, Pane } from 'react-leaflet'
import { divIcon } from 'leaflet'
import { Link } from 'react-router-dom'

import enhancer from '~/redux/allEnhancer'
import AerialMapController from './aerialMap'
import _ from "lodash";

class AerialMap extends AerialMapController {
  constructor() {
    super()
    this.state = {showIdTerm: 10}
  }
  render() {
    if (!this.props.auth.id) return <span></span>
    const { panorama, actions, history } = this.props
    const { showIdTerm } = this.state
    // 淀競馬場を中心に
    const position = [34.905360769, 135.72741508]

    let zoom = 14

    const pano = panorama.panorama
    const panos = panorama.panoramas
    const panoIndex = _.findIndex(panos, pa => pa.id == pano.id)
    const fromPano = panos[panoIndex]
    const pathNames = history.location.pathname.split('/')
    if (fromPano && pathNames.length === 3) {
      console.log('from', fromPano)
      position = [fromPano.latitude,fromPano.longitude]
      zoom = 18
    }

    return (
      <div>
        <Map center={position} zoom={zoom} minZoom={12} maxZoom={18} onClick={(e) => this.onMapClick(e)} onZoomend={(e) => this.zoomend(e)}>
          <TileLayer
            url="https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg"
            attribution="<a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>国土地理院</a>"
          />
          <Pane name='marker-wrapper'>
            {panorama.pin_infos.map((pano, i) => {
            return (
              <Marker key={i} position={[pano.latitude, pano.longitude]} alt="Marker">
                <Popup>
                  {pano.ids.map((id, i) => <div key={id}>
                    <Link
                      to={"/panorama/"+id}
                      className="mr-2"
                      onClick={() =>
                        actions.setPanorama({id, text: pano.texts[i], image_url: pano.image_urls[i]})
                      }
                    >
                      {pano.texts[i]}
                    </Link>
                  </div>)}
                </Popup>
              </Marker>
            )
          })}
          </Pane>
          <Pane name='number-wrapper' style={{zIndex: 1000}}>
            {panorama.pin_infos.map((pano, i) => {
            if (Number(pano.ids[0].slice(0, -2)) % showIdTerm == 0 || !Number(pano.ids[0].slice(0, -2)) && Number(pano.ids[0].slice(0, -4)) % showIdTerm == 0)
              return <Marker
                key={i} position={[pano.latitude+0.00025, pano.longitude]}
                icon={divIcon({className: 'marker-id', html: "<div>"+pano.ids[0].slice(0, -2)+"</div>"})}
              />
            return <span></span>
          })}
          </Pane>
        </Map>
      </div>
    )
  }
  zoomend(e) {
    if (e.target._zoom <= 15) {
      this.setState({showIdTerm: 10})
    } else {
      this.setState({showIdTerm: 1})
    }
  }
}

export default enhancer(AerialMap)
