import { Link } from 'react-router-dom'
import React from 'react'

import enhancer from '~/redux/allEnhancer'
import HomeController from './home'
/* eslint no-restricted-globals: 0 */
class Home extends HomeController {
  toApp(url, storeUrl) {
    this.hasApp = false
    const iframe = document.createElement('iframe')
    iframe.src = url
    iframe.hidden = true
    document.body.appendChild(iframe)
    window.focus()
    window.addEventListener('blur', () => this.hasApp = true);
    setTimeout(() => {
      if (this.hasApp) return
      location.href = storeUrl
    }, 2000)
  }
  render() {
    if (!this.props.auth.id) return <span></span>
    return (
      <div id="home">
        <div className="logout-wrapper">
          <Link to="/signout" className="logout">
            <i className="fas fa-sign-out-alt"></i>
          </Link>
        </div>
        <div className="home-list">
          <div className="list-title">
            <h2><span className="daiko">大興電気</span></h2>
            <hr/>
            <h2><span className="toyo">東陽電設工業</span></h2>
          </div>
          <div className="list-col">
            <div className="home-item">
              <Link className="bg-common" to="/map">
                <i className="fas fa-map-marked-alt"></i>
              </Link>
              <p>Map</p>
            </div>
          </div>
          <div className="list-col">
            <div className="home-item">
              <a className="bg-daiko" href="https://drive.google.com/drive/u/0/folders/1aYGCMZWkf4-s973ZB2F6js-lkRGZJ6m8">
                <i className="fas fa-id-card"></i>
              </a>
              <p>タイムカード</p>
            </div>
            <div className="home-item">
              <a className="bg-daiko" href="https://docs.google.com/spreadsheets/d/1GqZ5V7Q86FVhhVMd7aIORPTdovWillQnyauHxlJfOcY/edit#gid=915009784">
                <i className="fas fa-calendar-check"></i>
              </a>
              <p>スケジュール</p>
            </div>
            <div className="home-item">
              <a className="bg-toyo" href="https://docs.google.com/spreadsheets/d/1s_4pVEmoSH6FvTYKqpAtK6SGZSgZjh6SiKuTgFRTCk8/edit#gid=1721717274">
                <i className="fas fa-calendar-check"></i>
              </a>
              <p>スケジュール</p>
            </div>
            <div className="home-item">
              <a className="bg-toyo" href="https://drive.google.com/drive/u/0/folders/1gGSykbq1HaSrlXlh41T4eEpiFzcQpR3q">
                <i className="fas fa-id-card"></i>
              </a>
              <p>タイムカード</p>
            </div>
          </div>
          <div className="list-col">
            <div className="home-item">
              <a className="bg-daiko" href="https://drive.google.com/drive/u/0/folders/1iGbn7bqCccy5gPvuZrC8nCG4n4HFRRl3">
                <i className="fas fa-edit"></i>
              </a>
              <p>工事日報</p>
            </div>
            <div className="home-item">
              <a className="bg-daiko" href="https://www.dropbox.com/sh/p64pewk5nnqhs1i/AABrvtgiEVVub-Hs57MR7jQSa?dl=0">
                <i className="fas fa-folder"></i>
              </a>
              <p>工事フォルダ</p>
            </div>
            <div className="home-item">
              <a className="bg-toyo" href="https://www.dropbox.com/sh/8pgfiowwpks3khh/AADzNK3NefxhrsVjj-GZ27dfa?dl=0">
                <i className="fas fa-folder"></i>
              </a>
              <p>工事フォルダ</p>
            </div>
            <div className="home-item">
              <a className="bg-toyo" href="https://drive.google.com/drive/u/0/folders/1mJRBOisUeKWsIqCJM97Fr-WkD4io6w8H">
                <i className="fas fa-edit"></i>
              </a>
              <p>工事日報</p>
            </div>
          </div>
          <div className="list-col">
            <div className="home-item">
              <a className="bg-daiko" href="https://www.dropbox.com/sh/vlt9spzr9thajra/AADGZiJp5s0xIUI4TtLusXhga?dl=0">
                <i className="fas fa-file-alt"></i>
              </a>
              <p>線路図＆規程集</p>
            </div>
            <div className="home-item">
              <a className="bg-daiko" href="https://www.dropbox.com/sh/zzc60yj7ige75w9/AAAxf1etUAw1BPjzJHihV8Ysa?dl=0">
                <i className="fas fa-clipboard-check"></i>
              </a>
              <p>完了工事フォルダ</p>
            </div>
            <div className="home-item">
              <a className="bg-toyo" href="https://www.dropbox.com/sh/nwh1ughscwe92uk/AACckzcVZ8zo1Zu5LbtPEPT4a?dl=0">
                <i className="fas fa-clipboard-check"></i>
              </a>
              <p>完了工事フォルダ</p>
            </div>
            <div className="home-item">
              <a className="bg-toyo" href="https://www.dropbox.com/sh/apmuvzdiqxkrrd0/AACqusWCq22jnoQt-46q-6nia?dl=0">
                <i className="fas fa-file-alt"></i>
              </a>
              <p>線路図＆規程集</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default enhancer(Home)
