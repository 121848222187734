import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import enhancer from '~/redux/allEnhancer'
import HeaderController from './header'

class Header extends HeaderController {
  render() {
    const { auth } = this.props
    const { history } = this.props
    const pathNames = history.location.pathname.split('/')
    let panoramaId = null
    if (pathNames[1] === 'panorama') {
      panoramaId = pathNames[2]
    }
    return (
      <header>
        {auth.token &&
          <div className="header-contents">
            <Link to="/" className=""><i className="fas fa-home"></i></Link>
            <Link to="/map" className=""><i className="fas fa-map-marker-alt"></i></Link>
            {panoramaId && <Link to={"/map/"+panoramaId} className=""><i className="fas fa-long-arrow-alt-left"></i></Link>}
          </div>
        }
      </header>
    )
  }
}

export default enhancer(Header)
