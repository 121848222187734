import { config } from '~/utilsjs'

const initialAppState = {
  count: 0,
  next: null,
  previous: null,
  pin_infos: [],
  panoramas: [],
  panorama: {
    id: '',
    text: '',
    image_url: null,
  }
}

const makePinInfos = (panos) => {
  let pin_infos = []
  panos.forEach((pano, i) => {
    const nextPano = i+1 == panos.length ? {} : panos[i+1]
    const prevPano = i == 0 ? {} : panos[i-1]
    if (pano.latitude == nextPano.latitude && pano.longitude == nextPano.longitude) {
      pano.ids = [pano.id, nextPano.id]
      pano.texts = [pano.text, nextPano.text]
      pano.image_urls = [pano.image_url, nextPano.image_url]
      pin_infos.push(pano)
    } else if (!(pano.latitude == prevPano.latitude && pano.longitude == prevPano.longitude)) {
      pano.ids = [pano.id]
      pano.texts = [pano.text]
      pano.image_urls = [pano.image_url]
      pin_infos.push(pano)
    }
  })

  return pin_infos
}

const auth = (state = initialAppState, action) => {
  if (action.type === 'setPanoramas') {
    const panos = action.panoramas
    const pin_infos = makePinInfos(panos)
    return {
      ...state,
      count: action.count,
      pin_infos,
      panoramas: action.panoramas,
    }
  } else if (action.type === 'addPanoramas') {
    const panos = [...state.panoramas, ...action.panoramas]
    const pin_infos = makePinInfos(panos)
    return {
      ...state,
      pin_infos,
      panoramas: panos,
    }
  } else if (action.type === 'setPanorama') {
    return {
      ...state,
      panorama: {
        id: action.id,
        text: action.text,
        image_url: config.urls.api.https+action.image_url,
      }
    }
  } else if (action.type === 'signOut'){
    return initialAppState
  } else {
    return state
  }
}

export default auth